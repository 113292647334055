import { Avatar, Box, Card, CardHeader, Grid, Typography } from '@mui/material';
import startCase from 'lodash/startCase';
import { useAuth } from 'modules/auth/hooks';
import { CommonPaper } from 'modules/common/components/common-paper/common-paper.component';
import { useTranslation } from 'modules/common/hooks';
import { FunctionComponent } from 'react';
import { useUserGuideStyles } from 'views/overview/partials/user-guide/user-guide.styles';

export const UserGuidePartial: FunctionComponent = () => {
  const classes = useUserGuideStyles();
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <Grid container spacing={5} alignItems={'stretch'}>
      <Grid item xs={5} md={5} lg={5}>
        <CommonPaper bodyProps={{ minHeight: '100px' }}>
          <Box className={classes.topSection}>
            <Typography variant="h4">
              {/* <Trans i18nKey="dashboard.user-guide.title" values={{ fullName: `${startCase(user.firstName.toLowerCase())} ${startCase(user.lastName.toLowerCase())}` }}  /> */}
              Welcome {startCase(user.firstName.toLowerCase())} {startCase(user.lastName.toLowerCase())} to HELM BRIDGE
            </Typography>
          </Box>
        </CommonPaper>
      </Grid>

      <Grid item xs={7} md={7} lg={7}>
        <CommonPaper bodyProps={{ minHeight: '100px' }}>
          <Box className={classes.cardsSection}>
            <Grid container spacing={{ xl: 4, lg: 2, xm: 2, md: 2, xs: 2 }}>
              <Grid item xs={12} md={12} lg={12} xl={6}>
                <Card className={classes.cardCommon}>
                  <CardHeader
                    avatar={<Avatar aria-label="recipe" src="/static/avatars/Mostafa.jpg" />}
                    title={t('dashboard.user-guide.team-cards.first.title')}
                    subheader={t('dashboard.user-guide.team-cards.first.summary')}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} md={12} lg={12} xl={6}>
                <Typography lineHeight={'15px'} fontSize={'14px'} letterSpacing={'0.03rem'}>
                  For technical questions and to share your ideas and feedback about the portal
                </Typography>
                <Typography fontSize={'14px'} lineHeight={'28px'} letterSpacing={'0.03rem'}>
                  <strong>Phone:</strong> +49 1725273177
                </Typography>
                <Typography
                  fontSize={'14px'}
                  lineHeight={'10px'}
                  letterSpacing={'0.03rem'}
                  style={{ lineBreak: 'anywhere' }}
                >
                  <strong>Email:</strong> mostafa.jannatipour@helmag.com
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </CommonPaper>
      </Grid>
    </Grid>
  );
};

import { roqMakeStyles } from 'modules/common/utils';

export const useUserGuideStyles = roqMakeStyles(() => ({
  topSection: {
    padding: '20px'
  },
  cardsSection: {
    padding: '14px',
    '& .MuiCardHeader-root': {
      paddingTop: '12px',
      paddingLeft: '20px'
    },
    '& .MuiCardHeader-title': {
      lineHeight: '20px',
      fontSize: '16px',
      color: '#000000',
      marginTop: '5px'
    },
    '& .MuiCardHeader-subheader': {
      lineHeight: '20px',
      color: '#000000',
      marginTop: '0px'
    },
  },
  cardCommon: {
    height: '40p%',
    backgroundColor: '#C3C6CB',
  },
  cardItem: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Divider, Grid, IconButton, Skeleton, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { LoadingSkeleton, RoqLink } from 'modules/common/components';
import { CommonPaper } from 'modules/common/components/common-paper/common-paper.component';
import { CustomTooltip } from 'modules/common/components/custom-tooltip/custom-tooltip.component';
import { NoDataComponent } from 'modules/common/components/no-data';
import { useRouter, useTranslation } from 'modules/common/hooks';
import { OperationStatusInterface } from 'modules/common/interfaces';
import { roundDecimals } from 'modules/common/utils/round-decimals.util';
import { ContractTypeEnum } from 'modules/contract-details/enums';
import { TablePaginationInterface } from 'modules/contract-release/hooks/use-fetch-contract-release.hook';
import { ContractInterface } from 'modules/contract-release/interfaces';
import { FormAlert } from 'modules/forms/components';
import { UserRoleEnum } from 'modules/user-invites/enums';
import { useUserRoles } from 'modules/users/hooks';
import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import routes from 'routes';
import { useFetchContractDetails } from 'views/contract-details/hooks';
import { useContractsCardStyles } from 'views/overview/partials/contracts-card/contracts-card.styles';

const getTotalUsedQuantityPercentage = (status) => {
  const roundedTotalUsed = roundDecimals(Number(status.totalUsedQuantity));
  const roundedTotal = roundDecimals(Number(status.totalQuantity));
  return (roundedTotalUsed / roundedTotal) * 100;
};

const ViewAllButton = () => {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <Button
      onClick={() =>
        router.push({
          route: routes.contracts,
        })
      }
      variant="outlined"
      color="primary"
    >
      {t('recent_orders.table.view-all')}
    </Button>
  );
};

interface IProps {
  id: string;
  type: string;
  companyName: string;
}
const ContractDetails = (props: IProps) => {
  const router = useRouter();
  const classes = useContractsCardStyles();
  const { roles } = useUserRoles();
  const { status, initiateOperation, isLoading } = useFetchContractDetails();
  const hideCompanyName = roles?.includes(UserRoleEnum.CUSTOMER_USER) ? 'none' : 'display';

  useEffect(() => {
    void initiateOperation({
      id: props.id,
      type: props.type === 'spot' ? ContractTypeEnum.SPOT : ContractTypeEnum.FRAME,
    });
  }, [props.id]);

  const contractDetailsdata = status?.result;

  const ContractDetailsLoading = () => (
    <Grid container item direction="column" px={2}>
      <Skeleton animation="wave" />
    </Grid>
  );

  if (isLoading) {
    return <ContractDetailsLoading />;
  }

  return (
    <>
      {!status?.result ? (
        <NoDataComponent />
      ) : (
        <>
          <Grid item xs={3} pl={3} display={hideCompanyName}>
            <Typography variant="body2" className={classes.text}>
              {props.companyName}
            </Typography>
          </Grid>

          <Grid item xs={3} pl={2}>
            <RoqLink
              className={classes.link}
              onClick={() =>
                void router.push({
                  route: routes.contractDetails,
                  query: {
                    id: props.id,
                    type: props.type.toUpperCase(),
                  },
                })
              }
            >
              <Typography variant="body2" className={classes.text}>
                Contract {props.id}
              </Typography>
            </RoqLink>
          </Grid>
          {/* 
          <Grid container direction="column" justifyContent="center" item xs rowSpacing={2}>
            <Grid item>
              <LinearProgress
                value={getTotalUsedQuantityPercentage(contractDetailsdata?.status)}
                variant="determinate"
                className={classes.totalQuantityProgress}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2" component="span" className={classes.quantityLabel}>
                Used quantity (MT):{' '}
              </Typography>
              <Typography variant="body2" component="span" className={classes.quantityValue}>
                {roundDecimals(contractDetailsdata?.status.totalUsedQuantity)} /{' '}
                {roundDecimals(contractDetailsdata?.status.totalQuantity)}
              </Typography>
            </Grid>
          </Grid> */}

          <Grid container direction="row" justifyContent="flex-end" item xs={2}>
            <CustomTooltip title={'View contract details'}>
              <IconButton
                onClick={() => {
                  void router.push({
                    route: routes.contractDetails,
                    query: {
                      id: props.id,
                      type: props.type.toUpperCase(),
                    },
                  });
                }}
                className={classes.tableIcon}
              >
                <ArrowForwardIcon sx={{ color: '#9e9e9e' }} />
              </IconButton>
            </CustomTooltip>
          </Grid>
        </>
      )}
    </>
  );
};

const Loading = () => {
  const classes = useContractsCardStyles();
  const { t } = useTranslation();

  return (
    <CommonPaper
      title={
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          className={classes.status}
          columnGap={1}
        >
          <Grid item xs="auto">
            <Typography variant="h4">{t('title_contracts')}</Typography>
          </Grid>
          <Grid item xs="auto" paddingRight={2}>
            <ViewAllButton />
          </Grid>
        </Grid>
      }
    >
      <LoadingSkeleton />
    </CommonPaper>
  );
};

interface ContractsCardPartialProps {
  children?: ReactNode;
  fetchContractsOperation: {
    status: OperationStatusInterface<{
      data: ContractInterface[];
      totalCount: number;
    }>;
    isLoading: boolean;
    initiateOperation: (params: TablePaginationInterface) => Promise<{
      data: ContractInterface[];
      totalCount: number;
    }>;
    resetStatus: () => void;
    filter: TablePaginationInterface;
  };
}

export const ContractsCardPartial: FunctionComponent<ContractsCardPartialProps> = (
  props: ContractsCardPartialProps,
) => {
  const { fetchContractsOperation } = props;
  const { t } = useTranslation();
  const classes = useContractsCardStyles();

  const { isLoading, status, initiateOperation, resetStatus, filter: operationFilter } = fetchContractsOperation;

  useEffect(() => {
    void initiateOperation(operationFilter);
  }, []);

  const data = status?.result?.data.filter((d) => d.businessPartnerInternal !== null).slice(0, 3);

  if (isLoading) {
    return <Loading />;
  }

  return data?.length ? (
    <CommonPaper
      title={
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          className={classes.status}
          columnGap={1}
        >
          <Grid item xs="auto">
            <Typography variant="h4">{t('title_contracts')}</Typography>
          </Grid>
          <Grid item xs="auto" paddingRight={2}>
            <ViewAllButton />
          </Grid>
        </Grid>
      }
    >
      {!status?.result ? (
        <NoDataComponent containerStyleProperties={{ height: '400px' }} />
      ) : (
        <Grid container direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          {data.map((d, index) => (
            <React.Fragment key={index}>
              <ContractDetails
                id={d.helmContractId}
                type={d.type}
                companyName={d.businessPartnerInternal?.name ? d.businessPartnerInternal?.name : 'N/A'}
              />

              {index !== data.length - 1 && (
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
      )}

      {!isLoading && !!status?.error && (
        <FormAlert open error={status.error} autoHideDuration={3000} onClose={resetStatus} />
      )}
    </CommonPaper>
  ) : (
    <></>
  );
};

/* eslint-disable @roq/no-eslint-disable */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types, @roq/no-invalid-page-resource */
import { compose } from '@reduxjs/toolkit';
import { withAcl } from 'modules/acl';
import { withAuth } from 'modules/auth/hocs';
import { withLocale } from 'modules/locale';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { OverviewView } from 'views/overview/overview.view';

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common'])),
  },
});

export default compose(withAuth(), withAcl({ permission: 'dashboard.read' }), withLocale)(OverviewView);

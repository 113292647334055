import { Icon, Stack, Typography } from '@mui/material';
import { Paperclip } from '@phosphor-icons/react';

export const InvoicesLeftHeaderComponent = (): React.ReactElement => 
  <Stack direction={"row"} alignItems={"center"}>
    <Icon>
      <Paperclip size={24} color='#a0d7d0' />
    </Icon>
    <Typography color={'black'} fontSize={18} variant='semiBold' >
      {'Latest Invoices'}
    </Typography>
  </Stack>
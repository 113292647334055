import { Grid } from '@mui/material';
import { FunctionComponent } from 'react';
import { OrdersCard } from 'views/overview/partials/orders-card/orders-card.partial';

export const SummaryPartial: FunctionComponent = () => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <OrdersCard />
    </Grid>

    {/* <AclProvider permission="adminDashboardContractsCard.read">
        <Grid item xs={12}>
          <ContractsCardPartial fetchContractsOperation={getFetchContractsOperation()} />
        </Grid>
      </AclProvider>

      <AclProvider permission="customerDashboardContractsCard.read">
        <Grid item xs={12}>
          <ContractsCardPartial fetchContractsOperation={getFetchContractsOperation()} />
        </Grid>
      </AclProvider> */}
  </Grid>
);

import { CircularProgress, SxProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { CustomLabel } from 'modules/common/components/custom-label/custom-label.component';
import { Loader } from 'modules/common/components/loader';
import { ContractTypeEnum } from 'modules/contract-details/enums';
import { OrderTypeListFilter, useOrderTypeList } from 'modules/forms/components/order-type-autocomplete/hooks';
import { OrderTypeAutocompleteOption } from 'modules/forms/components/order-type-autocomplete/interfaces';
import { useEffect, useMemo, useState } from 'react';

interface IProps {
  label?: string;
  onChange?: (selectedValue: OrderTypeAutocompleteOption) => void;
  value: OrderTypeAutocompleteOption;
  placeholder?: string;
  multiple?: boolean;
  filter: OrderTypeListFilter;
  disabled?: boolean;
  defaultToFirst?: boolean;
  inputStyle?: SxProps;
}

export const OrderTypeAutocomplete: React.FunctionComponent<IProps> = (props: IProps) => {
  const { onChange, placeholder, disabled, label, defaultToFirst, filter = {}, inputStyle = {} } = props;
  const { data: orderTypeList, isLoading, handleFilterChange } = useOrderTypeList({ initialFilter: filter, disabled });
  const [isDefaultSet, setIsDefaultSet] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    handleFilterChange(props.filter);
  }, [props.filter]);

  const spotOrdersOption: OrderTypeAutocompleteOption = {
    id: ContractTypeEnum.SPOT,
    labelPrefix: 'Spot Orders',
  };

  const { filteredOptions } = useMemo(() => {
    const options: OrderTypeAutocompleteOption[] = (orderTypeList?.contracts?.data || []).map((c) => ({
      id: c.helmContractId,
      contractId: c.helmContractId,
      kunnr: c.kunnr,
      labelPrefix: 'Call off',
    }));

    if (orderTypeList?.orders?.totalCount >= 1) {
      options.unshift(spotOrdersOption);
    }
    const filteredNewOptions = uniqBy([...options], 'id');
    return { filteredOptions: filteredNewOptions };
  }, [orderTypeList]);

  useEffect(() => {
    if (!isLoading) {
      if (find(filteredOptions, { id: props.value?.id })) {
        onChange?.(props.value);
      } else if (isEmpty(filteredOptions)) {
        onChange?.(null);
      }
      // if (props?.value) {
      //   onChange?.(props.value);
      // }
      if (defaultToFirst && !props.value && filteredOptions?.length) {
        onChange?.(filteredOptions?.[0]);
        setIsDefaultSet(true);
      }
    }
  }, [isLoading]);

  const getLabel = (option: OrderTypeAutocompleteOption) => {
    if (option?.contractId) {
      return `${option.labelPrefix} - ${option.contractId}`;
    }
    return option?.labelPrefix;
  };

  const inputProps = isLoading ? { endAdornment: <CircularProgress size={16} /> } : {};

  return (
    <>
      <CustomLabel value={label} />
      <Autocomplete
        options={filteredOptions}
        disabled={disabled}
        sx={inputStyle}
        onChange={(_: React.SyntheticEvent, newValue) => {
          onChange?.(newValue as OrderTypeAutocompleteOption);
          if (newValue) {
            setOpen(false);
          }
        }}
        open={open}
        onBlur={() => setOpen(false)}
        onClick={() => setOpen(true)}
        onFocus={() => setOpen(true)}
        loading={isLoading}
        loadingText={<Loader />}
        value={props.value || null}
        getOptionLabel={getLabel}
        isOptionEqualToValue={(option, value) => option.id.toString() === value.id.toString()}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            variant="filled"
            onClick={() => {
              setOpen(true);
            }}
            placeholder={props.value ? '' : placeholder || 'All Items'}
            InputProps={{ ...params.InputProps, ...inputProps }}
          />
        )}
        disableCloseOnSelect={true}
      />
    </>
  );
};

import { MainLayout } from 'layouts/main';
import { CustomerViewWrapper } from 'modules/current-business-partner-selector';
import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { OverviewLayoutPartial } from 'views/overview/partials';

export const OverviewView = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <MainLayout title={t('title_overview')} noPadding>
      <CustomerViewWrapper inCard={false} >
        <OverviewLayoutPartial />
      </CustomerViewWrapper>
    </MainLayout>
  );
};

import { RootState } from 'configuration/redux/reducer';
import { useCurrentBusinessPartnerInternal } from 'modules/current-business-partner-selector/hooks';
import { AddressOptionInterface } from 'modules/forms/components/address-search-multi/address-search-multi.component';
import { OrderTypeAutocompleteOption } from 'modules/forms/components/order-type-autocomplete/interfaces';
import { ProductAutocompleteOptionInterface } from 'modules/forms/components/product-autocomplete-search/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { OrderTypeEnum } from 'views/orders/enums';
import {
  setFilter,
  setSelectedOrderType,
  setSelectedProduct,
  setSelectedShipToAddresses,
} from 'views/overview/overview-filter.slice';

export interface OverviewFilterInterface {
  selectedProduct?: ProductAutocompleteOptionInterface;
  productId?: string;
  orderType?: OrderTypeEnum;
  frameContractIds?: string[];
  shipToAddressIds?: string[];
  kunnrIds?: string[];
}

export const useOverviewFilter = () => {
  const selectedProduct = useSelector((state: RootState) => state.overviewFilter.selectedProduct);
  const selectedOrderType = useSelector((state: RootState) => state.overviewFilter.selectedOrderType);
  const selectedShipToAddresses = useSelector((state: RootState) => state.overviewFilter.selectedShipToAddresses);
  const { currentBusinessPartnerInternal } = useCurrentBusinessPartnerInternal();
  const defaultFilter: OverviewFilterInterface = {};
  const dispatch = useDispatch();

  const onProductChange = (product: ProductAutocompleteOptionInterface) => {
    dispatch(setSelectedProduct(product));
    dispatch(setSelectedOrderType(null));
  };

  const onOrderTypeChanged = (newOrderType: OrderTypeAutocompleteOption) => {
    dispatch(setSelectedOrderType(newOrderType));
  };

  const onShipToAddressesChanged = (newAddresses: AddressOptionInterface[]) => {
    dispatch(setSelectedShipToAddresses(newAddresses));
  };

  const buildFilter = () => {
    const filter = { ...defaultFilter };

    const kunnr = currentBusinessPartnerInternal?.kunnr || selectedProduct?.kunnr;
    if (kunnr) {
      filter.kunnrIds = [kunnr];
    }
    if (selectedProduct) {
      filter.productId = selectedProduct.id;
    }

    if (selectedOrderType?.id === OrderTypeEnum.SPOT) {
      filter.orderType = OrderTypeEnum.SPOT;
    } else if (selectedOrderType?.id) {
      filter.orderType = OrderTypeEnum.CALL_OFF;
      filter.frameContractIds = [selectedOrderType.id];
    }

    if (selectedShipToAddresses?.length >= 1) {
      filter.shipToAddressIds = selectedShipToAddresses.map((a) => a.id);
    }
    return filter;
  };

  const onApply = (): OverviewFilterInterface => {
    const newFilter = buildFilter();
    dispatch(setFilter(newFilter));
    return newFilter;
  };

  const filter = useSelector((state: RootState) => state.overviewFilter.filter) || buildFilter();

  return {
    selectedProduct,
    onProductChange,
    setSelectedProduct,
    onOrderTypeChanged,
    selectedOrderType,
    onShipToAddressesChanged,
    selectedShipToAddresses,
    filter,
    onApply,
  };
};

import { Theme } from '@mui/material';
import { roqMakeStyles } from 'modules/common/utils';
import { ThemeEnum } from 'modules/theme/enums';

export const useContractsCardStyles = roqMakeStyles((theme: Theme) => ({
  totalQuantityProgress: {
    '&.MuiLinearProgress-determinate': {
      backgroundColor: '#EBF4FB',
      border: '8px #3890DB',
      borderRadius: '8px',
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#3890DB',
    },
  },
  divider: {
    backgroundColor: '#e9eaed',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  link: {
    cursor: 'pointer',
  },
  text: {
    fontWeight: 500,
    color: theme.palette.mode === ThemeEnum.LIGHT ? '#212121' : theme.palette.common.white,
  },
  quantityLabel: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    color: theme.palette.mode === ThemeEnum.LIGHT ? '#0E1A2E' : theme.palette.common.white,
  },
  quantityValue: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    color: theme.palette.mode === ThemeEnum.LIGHT ? '#0E1A2E' : theme.palette.common.white,
  },
}));

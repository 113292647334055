import { Box, Button, Grid } from '@mui/material';
import { AddressInterface } from 'modules/addresses/interfaces';
import { CustomLabel } from 'modules/common/components/custom-label/custom-label.component';
import { AddressSearchMulti } from 'modules/forms/components/address-search-multi/address-search-multi.component';
import { OrderTypeAutocompleteOption } from 'modules/forms/components/order-type-autocomplete/interfaces';
import { OrderTypeAutocomplete } from 'modules/forms/components/order-type-autocomplete/order-type-autocomplete.component';
import {
  ProductAutocompleteOptionInterface,
  ProductAutocompleteSearchSingle,
} from 'modules/forms/components/product-autocomplete-search';
import { useEffect, useState } from 'react';
import { OrderTypeEnum } from 'views/orders/enums';
import { useOverviewFilterStyles } from 'views/overview/partials/overview-filter/overview-filter.styles';

interface OverviewFilterPartialProps {
  selectedProduct: ProductAutocompleteOptionInterface;
  onProductChange?: (product: ProductAutocompleteOptionInterface) => void;
  onOrderTypeChange?: (orderType: OrderTypeAutocompleteOption) => void;
  onShipToAddressesChanged?: (addresses: AddressInterface[]) => void;
  selectedShipToAddresses?: AddressInterface[];
  orderType: OrderTypeAutocompleteOption;
  onApply?: () => void;
  applyOnFirstLoad?: boolean;
}

export const OverviewFilterPartial = ({
  selectedProduct,
  onProductChange,
  onOrderTypeChange,
  orderType,
  onShipToAddressesChanged,
  selectedShipToAddresses,
  onApply,
  applyOnFirstLoad,
}: OverviewFilterPartialProps): React.ReactElement => {
  const classes = useOverviewFilterStyles();
  const [isFirstFilterApplied, setIsFirstFilterApplied] = useState(false);

  useEffect(() => {
    if (applyOnFirstLoad && !isFirstFilterApplied && selectedProduct && orderType) {
      onApply?.();
      setIsFirstFilterApplied(true);
    }
  }, [selectedProduct, orderType, isFirstFilterApplied]);

  const orderTypes = [];
  if (orderType?.id === OrderTypeEnum.SPOT) {
    orderTypes.push(OrderTypeEnum.SPOT);
  } else if (orderType?.id) {
    orderTypes.push(OrderTypeEnum.CALL_OFF);
  }

  return (
    <Box className={classes.container}>
      <Grid container xs={12} alignItems={'end'} gap={'24px'} margin={'0px !important'}>
        <Box className={classes.inputContainer}>
          <CustomLabel value='Products' />
          <ProductAutocompleteSearchSingle 
            inputStyle={{
              '& .MuiFormControl-root': {
                margin: '0px !important',
              },
            }}
            isLabel={false}
            value={selectedProduct || null}
            onChange={onProductChange}
            placeholder="All Products"
            defaultToFirst={true}
          />
        </Box>
        <Box className={classes.inputContainer}>
          <OrderTypeAutocomplete
            inputStyle={{
              '& .MuiFormControl-root': {
                margin: '0px !important',
              },
            }}
            label={'Order Type'}
            filter={{ productIds: selectedProduct ? [selectedProduct.id] : undefined }}
            onChange={onOrderTypeChange}
            value={orderType}
            disabled={!selectedProduct?.id}
            defaultToFirst={true}
          />

        </Box>
        <Box className={classes.inputContainer} maxWidth={'28%'}>
          <AddressSearchMulti
            value={selectedShipToAddresses}
            onChange={onShipToAddressesChanged}
            orderTypes={orderTypes}
            contractId={orderType?.id || null}
            productId={selectedProduct?.id}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <Button
            disabled={!selectedProduct || !orderType}
            sx={{ height: 42, width: '100%' }}
            fullWidth={true}
            variant="contained"
            color="primary"
            size='large'
            onClick={onApply}
          >
            Apply
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};

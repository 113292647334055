import { useAsyncOperation } from 'modules/common/hooks';
import { UseAsyncHookInterface } from 'modules/common/hooks/use-async-operation.hook';
import { useFetchDocuments, useFetchDocumentsResultInterface } from 'modules/documents/hooks/use-fetch-documents.hook';
import { DocumentsFetchArgsInterface } from 'modules/documents/interfaces/documents-fetch-args.interface';

export interface FetchContractsDetailsArgsInterface {
  id: string,
  type: string,
  offset?: number,
  limit?: number
}

export const useFetchContractAttachments = (): UseAsyncHookInterface<DocumentsFetchArgsInterface, useFetchDocumentsResultInterface> => {
  const { fetchDocuments } = useFetchDocuments()
  const result = useAsyncOperation({
    callback: fetchDocuments
  })
  return result
}

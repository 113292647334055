import { RootState } from 'configuration/redux/reducer';
import { useCurrentBusinessPartnerInternal } from 'modules/current-business-partner-selector/hooks';
import { fetchOrderTypeListThunk } from 'modules/forms/components/order-type-autocomplete/slices/order-type-list.slice';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export interface OrderTypeListFilter {
  productIds?: string[];
  kunnrs?: string[];
}

export const useOrderTypeList = ({
  initialFilter,
  disabled,
}: {
  initialFilter: OrderTypeListFilter;
  disabled: boolean;
}) => {
  const dispatch = useDispatch();
  const { currentBusinessPartnerInternal } = useCurrentBusinessPartnerInternal();

  const orderTypeList = useSelector((state: RootState) => state.orderTypeList?.data);
  const isLoading = useSelector((state: RootState) => state.orderTypeList?.isLoading);
  const totalCount = useSelector((state: RootState) => state.orderTypeList?.totalCount);

  const filter: OrderTypeListFilter = initialFilter;

  const combineFilters = (newFilter: OrderTypeListFilter) => {
    if (currentBusinessPartnerInternal?.kunnr) {
      newFilter.kunnrs = [currentBusinessPartnerInternal.kunnr];
    }
    return newFilter;
  };

  useEffect(() => {
    if (!disabled && currentBusinessPartnerInternal?.kunnr) {
      dispatch(fetchOrderTypeListThunk(combineFilters(filter)));
    }
  }, [filter, currentBusinessPartnerInternal?.kunnr]);

  const handleFilterChange = useCallback(
    (newFilter) => {
      if (!disabled && currentBusinessPartnerInternal?.kunnr) {
        dispatch(fetchOrderTypeListThunk(combineFilters(newFilter)));
      }
    },
    [dispatch],
  );

  return {
    data: orderTypeList[JSON.stringify(filter)] || {},
    isLoading,
    totalCount,
    handleFilterChange,
  };
};

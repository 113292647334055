import { Theme } from '@mui/material';
import { roqMakeStyles } from 'modules/common/utils';

export const useOverviewFilterStyles = roqMakeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    padding: theme.spacing(3),
    background: theme.palette.background.paper,
  },
  inputContainer: {
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%',
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      flex: '3 3 0%'
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.up('xl')]: {
      flexGrow: 0.5
    },
  },
  formControl: {
    '&.MuiFormControl-root': {
      margin: '0px !important',
    },
  },
}));

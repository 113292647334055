import { gql } from '@apollo/client';
import { requestGql } from 'modules/common/utils/request-gql';
import { ContractAttachmentsInterface } from 'modules/contract-details/interfaces/contract-attachments.interface';

export interface FetchContractAttachmentsArgsInterface {
  id: string;
  type: string;
  offset?: number;
  limit?: number;
}

export interface FetchContractAttachmentsResponseInterface {
  data: ContractAttachmentsInterface[];
  totalCount?: number;
}

export interface UseFetchContractAttachmentsHookInterface {
  fetchContractAttachments: (
    args?: FetchContractAttachmentsArgsInterface,
  ) => Promise<FetchContractAttachmentsResponseInterface>;
}

export const useContractAttachments = (): UseFetchContractAttachmentsHookInterface => {
  const fetchContractAttachments = (
    args: FetchContractAttachmentsArgsInterface,
  ): Promise<FetchContractAttachmentsResponseInterface> =>
    requestGql(
      {
        query: gql`
          query ContractAttachmentsMetaData($id: String!, $type: AttachmentTypeEnum!, $limit: Int, $offset: Int) {
            contractAttachmentsMetaData(id: $id, type: $type, limit: $limit, offset: $offset) {
              data {
                sapObject
                objectId
                archiveId
                documentType
                fileName
                customerId
                description
                createdBy
                createdAt
                archiveDocId
              }
              totalCount
            }
          }
        `,
        variables: args,
      },
      null,
      'contractAttachmentsMetaData',
    );

  return {
    fetchContractAttachments,
  };
};
